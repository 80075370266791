import React from "react";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";

const SendEmail = () => {
  const sendEmail = async () => {
    try {
      const { data } = await api.get("/admin/bulkPackageMail");
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(
        err?.response?.data?.error || "Something went wrong",
        "failure"
      );
    }
  };
  return (
    <div>
      <h3>Send Email to all registered users</h3>
      <div className="my-8">
        <p className="mb-4">
          <strong>Subject: </strong>
          Jordanpicks.com packages out NOW!
        </p>
        <p>
          <strong>Body: </strong>
        </p>
        <div>
          <div>
            <p>🙋‍♂️ Hey NAME, are you ready for some action?</p>
            <p>
              🙋‍♂️ We've got some awesome packages for
              <a href="jordanspicks.com">Jordanspicks.com</a> that are out NOW!
              And guess what? You've got ($$) in your wallet to spend on them!
              💸
            </p>
            <ul>
              <li>
                PACKAGE NAME - GAME-PREVIEW
              </li>
            </ul>
            <p>
              Don't miss this chance to win big with our expert picks and profit
              guarantee. Trust us, you'll be laughing all the way to the bank.
              😉
            </p>
            <p>
              Hurry up and grab your package before the games start. We can't
              wait to see you on the winning side. 🏆
            </p>
            <p>All the best,</p>
            <p>Team Jordanspicks.com 😘</p>
          </div>
        </div>
      </div>
      <div>
        <Button theme="yellow" onClick={sendEmail}>
          Send Email
        </Button>
      </div>
    </div>
  );
};

export default SendEmail;
