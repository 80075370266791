import { useState, useEffect } from "react";
import api from "../../components/utils/api";
import dateFormatter from "../../components/utils/dateFormatter";
import myToast from "../../components/utils/myToast";
import Table from "../../components/common/Table";
import Pagination from "../../components/common/Pagination";

const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const getOrders = async () => {
    try {
      const { data } = await api.get(`/admin/allOrders?page=${page}`);
      console.log(data);
      setOrders(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  useEffect(() => {
    getOrders();
  }, [page]);

  return (
    <div>
      <h3>Orders</h3>
      <Table
        tHead={["S.No.", "Package Name", "Email", "Price", "Date"]}
        wrapperClass="my-8"
      >
        {orders.length > 0 ? (
          orders.map((item, index) => (
            <tr key={item._id}>
              <td>{(page - 1) * 10 + index + 1}</td>
              <td>{item.desc || item.package?.name || item.vslPackage?.name}</td>
              <td>{item.user?.email}</td>
              <td>{item.price || item.package?.price || item.vslPackage?.price}</td>
              <td>{dateFormatter(item.createdAt)}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center">
              No Orders Yet
            </td>
          </tr>
        )}
      </Table>
      <Pagination page={page} setPage={setPage} lastPage={lastPage} />
    </div>
  );
};

export default AllOrders;
