import { useState, useEffect } from "react";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import dateFormatter from "../../components/utils/dateFormatter";
import Table from "../../components/common/Table";
import Pagination from "../../components/common/Pagination";

const ContactedUsers = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const getContactedUsers = async () => {
    try {
      const { data } = await api.get(`/admin/contactedusers?page=${page}`);
      console.log(data);
      setUsers(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  useEffect(() => {
    getContactedUsers();
  }, [page]);

  return (
    <div>
      <h3>Contacted Users</h3>
      <Table
        tHead={["S.No.", "Name", "Mobile", "Email", "Contacted At", "Message"]}
        wrapperClass="my-8"
      >
        {users.length > 0 ? (
          users.map((item, index) => (
            <tr key={item._id}>
              <td>{(page - 1) * 10 + index + 1}</td>
              <td>{item.fName + " " + item.lName}</td>
              <td>{item.mobile}</td>
              <td>{item.email}</td>
              <td>{dateFormatter(item.createdAt)}</td>
              <td>{item.message}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center">
              No Data Found
            </td>
          </tr>
        )}
      </Table>
      <Pagination page={page} setPage={setPage} lastPage={lastPage} />
    </div>
  );
};

export default ContactedUsers;
