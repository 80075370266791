import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../components/utils/api";
import dateTimeSetter from "../../components/utils/dateTimeSetter";
import dateFormatter from "../../components/utils/dateFormatter";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";
import TextEditor from "../../components/common/TextEditor";
import { MdClose } from "react-icons/md";

const EditVSLPackage = () => {
  const { id } = useParams();

  const [name, setName] = useState("");
  const [actPrice, setActPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10).replace("T", " ")
  );
  const [startTime, setStartTime] = useState("00:00");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().slice(0, 10).replace("T", " ")
  );
  const [endTime, setEndTime] = useState("00:00");
  const [saleTitle, setSaleTitle] = useState("");
  const [description, setDescription] = useState({
    editorHtml: "",
  });
  const [gamePreview, setGamePreview] = useState("");
  const [bets, setBets] = useState([
    {
      title: "",
      result: "pending",
      show: true,
    },
  ]);
  const [videoURL, setVideoURL] = useState("");

  const updatePackage = async () => {
    // const newBets = bets.filter((item) => item.show);
    const newBets = bets.filter((item) => item.show).map((x) => x.title);
    try {
      const { data } = await api.put(`/admin/updateVslPackage/${id}`, {
        name,
        actPrice: +actPrice,
        discountedPrice: +discountedPrice,
        startDate: dateTimeSetter(startDate, startTime),
        endDate: dateTimeSetter(endDate, endTime),
        saleTitle,
        description: description.editorHtml,
        gamePreview,
        // bets: bets,
        bets: newBets,
        videoURL,
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(
        err?.response?.data?.error || "Something went wrong",
        "failure"
      );
    }
  };
  const getPackage = async () => {
    try {
      const { data } = await api.get(`/admin/getVslPackage/${id}`);
      console.log(data);
      setName(data.dta.name);
      setActPrice(data.dta.actPrice);
      setDiscountedPrice(data.dta.discountedPrice);
      const bets = data.dta.bets.map((item) => ({
        // ...item,
        title: item,
        show: true,
        // action: "update",
      }));
      setBets(bets);
      let startDateWithTime = dateFormatter(data.dta.startDate, true).split(
        " "
      );
      setStartDate(startDateWithTime[0]);
      setStartTime(startDateWithTime[1]);
      let endDateWithTime = dateFormatter(data.dta.endDate, true).split(" ");
      setEndDate(endDateWithTime[0]);
      setEndTime(endDateWithTime[1]);
      setSaleTitle(data.dta.saleTitle);
      setDescription({ editorHtml: data.dta.description });
      setGamePreview(data.dta.gamePreview);
      setVideoURL(data.dta.videoURL);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPackage();
  }, []);

  return (
    <div className="add-package">
      <h3>Update Package</h3>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          updatePackage();
        }}
      >
        <div className="flex gap-10 flex-wrap">
          <div>
            <div className="my-4">
              <div className="text-grey">Package Name</div>
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Sale Title</div>
              <input
                type="text"
                value={saleTitle}
                onChange={(e) => {
                  setSaleTitle(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Actual Price</div>
              <input
                type="text"
                value={actPrice}
                onChange={(e) => {
                  setActPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Discounted Price</div>
              <input
                type="text"
                value={discountedPrice}
                onChange={(e) => {
                  setDiscountedPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Start Date</div>
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                // min={new Date().toISOString().split("T")[0]}
                required
              />
              <input
                type="time"
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">End Date</div>
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                // min={new Date().toISOString().split("T")[0]}
                required
              />
              <input
                type="time"
                value={endTime}
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Game Preview</div>
              <input
                type="text"
                value={gamePreview}
                onChange={(e) => {
                  setGamePreview(e.target.value);
                }}
                required
              />
            </div>
           
            <div className="my-4">
              <div className="text-grey">Embedded Video URL</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={videoURL}
                onChange={(e) => {
                  setVideoURL(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Bets</div>
              {bets.length > 0 ? (
                bets.map((item, index) => (
                  <div key={index} className={!item.show ? "hidden" : ""}>
                    <div className="flex justify-between items-center">
                      <div className="text-grey">Bet {index + 1}</div>
                      <span
                        className="cursor-pointer p-2"
                        onClick={() => {
                          let temp = [...bets];
                          temp[index].show = false;
                          // temp[index].action = "delete";
                          setBets(temp);
                        }}
                      >
                        <MdClose />
                      </span>
                    </div>
                    <input
                      type="text"
                      value={item.title}
                      onChange={(e) => {
                        let temp = [...bets];
                        temp[index].title = e.target.value;
                        setBets(temp);
                      }}
                    />
                  </div>
                ))
              ) : (
                <div className="text-grey">No Bets</div>
              )}
              <div className="my-4">
                <Button
                  type="button"
                  theme="dark"
                  className="w-full"
                  onClick={() => {
                    setBets([
                      ...bets,
                      {
                        title: "",
                        // result: "pending",
                        show: true,
                        // action: "add",
                      },
                    ]);
                  }}
                >
                  Add Another Bet
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="text-grey">Content</div>
          <TextEditor
            documentContent={description}
            setDocumentContent={setDescription}
          />
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Update Package
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditVSLPackage;
