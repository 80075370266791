import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import dateFormatter from "../../components/utils/dateFormatter";
import Table from "../../components/common/Table";
import Pagination from "../../components/common/Pagination";
import Button from "../../components/common/Button";
import { MdEdit } from "react-icons/md";
import { PiSortAscending, PiSortDescending } from "react-icons/pi";

const UserList = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const updateUserWallet = async (id, wallet) => {
    try {
      const { data } = await api.post(`/admin/changeUserBalance/${id}`, {
        wallet: +wallet,
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState("");
  const getUsers = async () => {
    let append = "";
    if (searchKey && searchValue) {
      append = `&${searchKey}=${searchValue}`;
    }
    try {
      const { data } = await api.get(
        `/admin/allUsers?page=${page}${append}&sortBy=${order}`
      );
      console.log(data);
      setUsers(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  useEffect(() => {
    getUsers();
  }, [page, order]);

  return (
    <div>
      <div className="flex items-center justify-between gap-x-6 flex-wrap gap-y-4">
        <h3>Users</h3>
        <div className="flex items-center gap-2 flex-wrap">
          <select
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
          >
            <option value="">Search Key</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="mobile">Mobile</option>
          </select>
          <input
            type="text"
            placeholder="Search Value"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            theme="yellow"
            onClick={() => {
              setPage(1);
              getUsers();
            }}
          >
            Search
          </Button>
        </div>
      </div>
      <Table
        tHead={[
          "S.No.",
          "Name",
          <div
            className="flex gap-1 items-center cursor-pointer w-[6.4rem]"
            onClick={() => {
              order !== "" && setOrder("");
            }}
          >
            Created At{order === "" && <PiSortDescending />}
          </div>,
          "Email",
          "Mobile",
          <div
            className="flex gap-1 items-center cursor-pointer w-[9.6rem]"
            onClick={() => {
              setOrder(order === "desc" ? "asc" : "desc");
            }}
          >
            Packages Bought
            {order === "desc" ? (
              <PiSortDescending />
            ) : (
              order === "asc" && <PiSortAscending />
            )}
          </div>,
          "Status",
          "Wallet",
          "Gift Package",
        ]}
        wrapperClass="my-8"
      >
        {users.length > 0 ? (
          users.map((item, index) => (
            <tr key={item._id}>
              <td>{(page - 1) * 10 + index + 1}</td>
              <td>{item.name}</td>
              <td>{dateFormatter(item.createdAt)}</td>
              <td>{item.email}</td>
              <td>{item.mobile}</td>
              <td>{item.package.length}</td>
              <td>
                <span
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    navigate(`./${item._id}`);
                  }}
                >
                  <span title={item.remark} className="capitalize">
                    {item.status}
                  </span>
                  <MdEdit />
                </span>
              </td>
              <td className="flex items-center gap-2">
                <input
                  className="w-24"
                  type="text"
                  value={item.wallet}
                  onChange={(e) =>
                    setUsers(
                      users.map((user) =>
                        user._id === item._id
                          ? { ...user, wallet: e.target.value }
                          : user
                      )
                    )
                  }
                />
                <Button
                  theme="dark"
                  size="md"
                  onClick={() => updateUserWallet(item._id, item.wallet)}
                >
                  Update
                </Button>
              </td>
              <td>
                <Button
                  theme="yellow"
                  size="md"
                  onClick={() => {
                    navigate(`/user-list/gift/${item._id}`);
                  }}
                >
                  Gift
                </Button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7" className="text-center">
              No Users Found
            </td>
          </tr>
        )}
      </Table>
      <Pagination page={page} setPage={setPage} lastPage={lastPage} />
    </div>
  );
};

export default UserList;
