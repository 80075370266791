import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from "react";
import PassContext from "./components/utils/PassContext";

import "react-simple-toasts/dist/theme/success.css";
import "react-simple-toasts/dist/theme/failure.css";
import "./App.css";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import Layout from "./components/layout";
import Error from "./pages/Error";

import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import PackageList from "./pages/PackageList";
import LivePackages from "./pages/PackageList/components/LivePackages";
import PastPackages from "./pages/PackageList/components/PastPackages";
import DeletedPackages from "./pages/PackageList/components/DeletedPackages";
import SpecialPackageList from "./pages/SpecialPackageList";
import LiveSpecialPackages from "./pages/SpecialPackageList/components/LivePackages";
import DeletedSpecialPackages from "./pages/SpecialPackageList/components/DeletedPackages";
import Store from "./pages/Store";
import AddSpecialPackage from "./pages/AddSpecialPackage";
import AddPackage from "./pages/AddPackage";
import AddStore from "./pages/AddStore";
import EditPackage from "./pages/EditPackage";
import EditSpecialPackage from "./pages/EditSpecialPackage";
import EditStore from "./pages/EditStore";
import EditVSLPackage from "./pages/EditVSLPackage";
import VSLPackageList from "./pages/VSLPackageList";
import AddVSLPackage from "./pages/AddVSLPackage";
import AllOrders from "./pages/AllOrders";
import UserList from "./pages/UserList";
import SuspendUser from "./pages/SuspendUser";
import ContactedUsers from "./pages/ContactedUsers";
import SendEmail from "./pages/SendEmail";
import GiftPackage from "./pages/GiftPackage";

function App() {
  const [loggedUser, setLoggedUser] = useState("");
  const [loading, setLoading] = useState(true);
  const handleReturningUser = () => {
    if (localStorage.getItem("jordanToken")) {
      const decodedToken = jwtDecode(localStorage.getItem("jordanToken"));
      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("jordanToken");
        setLoggedUser("");
      } else {
        setLoggedUser("admin");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handleReturningUser();
  }, []);

  if (loading) return <div>Loading...</div>;

  const BrowserRouter = createBrowserRouter([
    {
      path: "/auth/",
      element: <Auth />,
    },
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: "/",
          element: <Layout />,
          children: [
            {
              path: "/",
              element: <Dashboard />,
            },
            {
              path: "/package-list",
              element: <PackageList />,
              children: [
                {
                  path: "/package-list/live",
                  element: <LivePackages />
                },
                {
                  path: "/package-list/past",
                  element: <PastPackages />,
                },
                {
                  path: "/package-list/deleted",
                  element: <DeletedPackages />,
                },
              ]
            },
            {
              path: "/special-package-list",
              element: <SpecialPackageList />,
              children: [
                {
                  path: "/special-package-list/live",
                  element: <LiveSpecialPackages />
                },
                {
                  path: "/special-package-list/deleted",
                  element: <DeletedSpecialPackages />,
                },
              ]
            },
            {
              path: "/store",
              element: <Store />,
            },
            {
              path: "/package-list/add-package",
              element: <AddPackage />,
            },
            {
              path: "/special-package-list/add-package",
              element: <AddSpecialPackage />,
            },
            {
              path: "/store/add-item",
              element: <AddStore />,
            },
            {
              path: "/package-list/edit-package/:id",
              element: <EditPackage />,
            },
            {
              path: "/special-package-list/edit-package/:id",
              element: <EditSpecialPackage />,
            },
            {
              path: "/store/edit-store/:id",
              element: <EditStore />,
            },
            {
              path: "/vsl-package-list",
              element: <VSLPackageList />,
            },
            {
              path: "/vsl-package-list/add-package",
              element: <AddVSLPackage />,
            },
            {
              path: "/vsl-package-list/edit-package/:id",
              element: <EditVSLPackage />,
            },
            {
              path: "/all-orders",
              element: <AllOrders />,
            },
            {
              path: "/user-list",
              element: <UserList />,
            },
            {
              path: "/user-list/:id",
              element: <SuspendUser />,
            },
            {
              path: "/user-list/send-email",
              element: <SendEmail />,
            },
            {
              path: "/user-list/gift/:id",
              element: <GiftPackage />,
            },
            {
              path: "/contacted-users",
              element: <ContactedUsers />,
            },
          ],
          errorElement: <Error />,
        },
      ],
    },
  ]);

  return (
    <PassContext.Provider value={{ loggedUser, setLoggedUser }}>
      <RouterProvider router={BrowserRouter} />
    </PassContext.Provider>
  );
}

export default App;
