import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PassContext from "../../components/utils/PassContext";
import Button from "../../components/common/Button";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import "./auth.css";

const Login = () => {
  const navigate = useNavigate();
  const { setLoggedUser } = useContext(PassContext);

  const loginUser = async () => {
    try {
      const { data } = await api.post(`/admin/login`, {
        email,
        password,
      });
      console.log(data);
      localStorage.setItem("jordanToken", data.dta);
      // localStorage.setItem("jordanTokenRefresh", data.refresh_token);
      setLoggedUser("admin");
      navigate("/");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className="h-screen w-screen flex justify-center items-center bg-darkblack">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          loginUser();
        }}
        className="auth-card"
      >
        <h4 className="mb-6">Enter Your Credentials</h4>
        <div className="mb-4">
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full"
            required
          />
        </div>
        <div className="w-full mb-4">
          <Button theme="yellow" className="w-full" type="submit">
            Login
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
