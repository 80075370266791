import { useState } from "react";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";

const AddStore = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [credits, setCredits] = useState("");

  const addStore = async () => {
    try {
      const { data } = await api.post("/admin/createStore", {
        name,
        price: +price,
        credits,
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  return (
    <div className="add-package">
      <h3>Add Store</h3>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          addStore();
        }}
      >
        <div>
          <div className="my-4">
            <div className="text-grey font-medium">Store Item Name</div>
            <input
              className="min-w-[20.5rem]"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
          </div>
          <div className="my-4">
            <div className="text-grey font-medium">Price</div>
            <input
              className="min-w-[20.5rem]"
              type="text"
              value={price}
              onChange={(e) => {
                if (!isNaN(e.target.value)) setPrice(e.target.value);
              }}
              required
            />
          </div>
          <div className="my-4">
            <div className="text-grey font-medium">Credits</div>
            <input
              className="min-w-[20.5rem]"
              type="text"
              value={credits}
              onChange={(e) => {
                if (!isNaN(e.target.value)) setCredits(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Add Store Item
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddStore;
