import { useState } from "react";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";
import TextEditor from "../../components/common/TextEditor";
import { MdClose } from "react-icons/md";

const AddPackage = () => {
  const [name, setName] = useState("");
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [yearlyPrice, setYearlyPrice] = useState("");
  const [discount, setDiscount] = useState("0");
  const [content, setContent] = useState({
    editorHtml: "",
  });
  const [preview, setPreview] = useState("");
  const [links, setLinks] = useState([
    {
      title: "",
      show: true,
    },
  ]);
  const [videoURL, setVideoURL] = useState(
    "https://www.youtube.com/embed/v7Iy5ikDy4A"
  );

  const addPackage = async () => {
    let myLinks = links.filter((link) => link.show).map((link) => link.title);

    try {
      const { data } = await api.post("/admin/createSpecialPackage", {
        name,
        monthlyPrice: +monthlyPrice,
        yearlyPrice: +yearlyPrice,
        links: myLinks,
        description: content.editorHtml,
        gamePreview: preview,
        videoURL,
        discount,
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  return (
    <div className="add-package">
      <h3>Add Special Package</h3>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          addPackage();
        }}
      >
        <div className="flex gap-x-10 flex-wrap">
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Package Name</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Monthly Price</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={monthlyPrice}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setMonthlyPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Yearly Price</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={yearlyPrice}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setYearlyPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">
                Subsequent Discount (%) on Standard Packages
              </div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={discount}
                onChange={(e) => {
                  if (!isNaN(e.target.value) && e.target.value <= 100)
                    setDiscount(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Game Preview</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={preview}
                onChange={(e) => {
                  setPreview(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">
                Embedded Video URL{" "}
                <span
                  className="text-xs text-yellow cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/watch?v=v7Iy5ikDy4A",
                      "_blank"
                    );
                  }}
                >
                  (View default)
                </span>
              </div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={videoURL}
                onChange={(e) => {
                  setVideoURL(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Links</div>
              {links.map((item, index) => (
                <div key={index} className={!item.show ? "hidden" : ""}>
                  <div className="flex justify-between items-center">
                    <div className="text-grey">Link {index + 1}</div>
                    <span
                      className="cursor-pointer p-2"
                      onClick={() => {
                        let temp = [...links];
                        temp[index].show = false;
                        setLinks(temp);
                      }}
                    >
                      <MdClose />
                    </span>
                  </div>
                  <input
                    className="min-w-[20.5rem]"
                    type="text"
                    value={item.title}
                    onChange={(e) => {
                      let temp = [...links];
                      temp[index].title = e.target.value;
                      setLinks(temp);
                    }}
                    placeholder="Enter Link"
                  />
                </div>
              ))}
              <div className="my-4">
                <Button
                  type="button"
                  theme="dark"
                  className="w-full"
                  onClick={() => {
                    setLinks([
                      ...links,
                      {
                        title: "",
                        show: true,
                      },
                    ]);
                  }}
                >
                  Add Another Link
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="text-grey font-medium">Content</div>
          <TextEditor
            documentContent={content}
            setDocumentContent={setContent}
          />
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Add Special Package
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddPackage;
