import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../components/utils/api";
import dateTimeSetter from "../../components/utils/dateTimeSetter";
import dateFormatter from "../../components/utils/dateFormatter";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";

const EditStore = () => {
  const { id } = useParams();

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [credits, setCredits] = useState("");

  const updateStoreItem = async () => {
    try {
      const { data } = await api.put(`/admin/updateStore/${id}`, {
        name,
        price: +price,
        credits
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  const getStore = async () => {
    try {
      const { data } = await api.get(`/admin/getStore/${id}`);
      console.log(data);
      setName(data.dta.name);
      setPrice(data.dta.price);
      setCredits(data.dta.credits);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getStore();
  }, []);

  return (
    <div className="add-package">
      <h3>Update Store Item</h3>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          updateStoreItem();
        }}
      >
        <div>
          <div className="my-4">
            <div className="text-grey">Store Item Name</div>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
          </div>
          <div className="my-4">
            <div className="text-grey">Price</div>
            <input
              type="text"
              value={price}
              onChange={(e) => {
                if (!isNaN(e.target.value)) setPrice(e.target.value);
              }}
              required
            />
          </div>
          <div className="my-4">
            <div className="text-grey">Credits</div>
            <input
              type="text"
              value={credits}
              onChange={(e) => {
                if (!isNaN(e.target.value)) setCredits(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Update Store Item
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditStore;
