import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../components/utils/api";
import dateFormatter from "../../components/utils/dateFormatter";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";

const SuspendUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [wallet, setWallet] = useState("");

  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");

  const updateUserStatus = async () => {
    try {
      const { data } = await api.patch(`/admin/updateUserStatus/${id}`, {
        status,
        remark
      });
      console.log(data);
      myToast(data.msg, "success");
      navigate("/user-list");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  const getUser = async () => {
    try {
      const { data } = await api.get(`/admin/getUser/${id}`);
      console.log(data);
      setName(data.dta.name);
      setEmail(data.dta.email);
      setMobile(data.dta.mobile);
      setCreatedAt(dateFormatter(data.dta.createdAt));
      setWallet(data.dta.wallet);
      setStatus(data.dta.status);
      setRemark(data.dta.remark);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="add-package">
      <div className="flex justify-between items-center gap-4">
        <h3>Update User Status</h3>
        <Button theme="dark" onClick={() => navigate("/user-list")}>
          Go Back
        </Button>
      </div>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          updateUserStatus();
        }}
      >
        <div className="my-4">
          <div className="text-grey">Name</div>
          <div>{name}</div>
        </div>
        <div className="my-4">
          <div className="text-grey">Email</div>
          <div>{email}</div>
        </div>
        <div className="my-4">
          <div className="text-grey">Mobile</div>
          <div>{mobile}</div>
        </div>
        <div className="my-4">
          <div className="text-grey">Created At</div>
          <div>{createdAt}</div>
        </div>
        <div className="my-4">
          <div className="text-grey">Wallet</div>
          <div>{wallet}</div>
        </div>
        <div className="my-4">
          <div className="text-grey">Status</div>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full"
            required
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="suspended">Suspended</option>
            <option value="deleted">Deleted</option>
          </select>
        </div>
        <div className="my-4">
          <div className="text-grey">Remark</div>
          <textarea
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            className="w-full"
            rows={5}
            required
          />
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Update User
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SuspendUser;
