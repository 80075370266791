import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import dateFormatter from "../../components/utils/dateFormatter";
import Button from "../../components/common/Button";
import Table from "../../components/common/Table";
import Pagination from "../../components/common/Pagination";
import Modal from "../../components/common/Modal";
import { MdEdit, MdDelete, MdLink } from "react-icons/md";

const VSLPackages = () => {
  const navigate = useNavigate();

  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const getPackages = async () => {
    try {
      const { data } = await api.get(`/admin/allVslPackages?page=${page}`);
      console.log(data);
      setPackages(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPackages();
  }, [page]);

  const updatePackage = async (id, status, runningStatus, result) => {
    try {
      const { data } = await api.patch(`/admin/updateVslPackageStatus/${id}`, {
        status,
        // runningStatus,
        result,
      });
      console.log(data);
      getPackages();
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  // const updatePackageBet = async (packageId, betId, result) => {
  //   try {
  //     const { data } = await api.patch(
  //       `/admin/updateVslPackageBetStatus/${packageId}`,
  //       {
  //         betId,
  //         status: result,
  //       }
  //     );
  //     console.log(data);
  //     getPackages();
  //   } catch (err) {
  //     console.log(err);
  //     myToast(err?.response?.data?.error || "Something went wrong", "failure");
  //   }
  // };
  const deletePackage = async (id) => {
    try {
      const { data } = await api.delete("/admin/deleteVslPackage/" + id);
      console.log(data);
      getPackages();
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  const dialogRef = useRef();
  const [modalBody, setModalBody] = useState("");

  const [accordian, setAccordian] = useState(0);

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <h3>VSL Packages</h3>
          <Button
            theme="yellow"
            onClick={() => {
              navigate("/vsl-package-list/add-package");
            }}
          >
            Add VSL Package
          </Button>
        </div>
        <Table
          tHead={[
            "S.No.",
            "Name",
            "Price",
            "Discounted Price",
            "Start Date",
            "End Date",
            "Title",
            "Content",
            "Bets",
            "Status",
            "Result",
            "Action",
          ]}
          wrapperClass="my-8"
        >
          {packages.length > 0 ? (
            packages.map((item, index) => (
              <>
                <tr key={item._id}>
                  <td>{(page - 1) * 10 + index + 1}</td>
                  <td>{item.name}</td>
                  <td>${item.actPrice}</td>
                  <td>${item.discountedPrice}</td>
                  <td>{dateFormatter(item.startDate)}</td>
                  <td>{dateFormatter(item.endDate)}</td>
                  <td>{item.saleTitle}</td>
                  <td
                    className="cursor-pointer underline text-yellow"
                    onClick={() => {
                      setModalBody(item.description);
                      dialogRef.current.showModal();
                    }}
                  >
                    show content
                  </td>
                  <td>
                    <span
                      className="cursor-pointer underline text-yellow"
                      onClick={() => {
                        if (accordian === item._id) setAccordian(0);
                        else setAccordian(item._id);
                      }}
                    >
                      {accordian === item._id ? "hide bets" : "show bets"}
                    </span>
                  </td>
                  <td>
                    <select
                      value={item.status}
                      onChange={(e) => {
                        updatePackage(
                          item._id,
                          e.target.value,
                          item.runningStatus,
                          item.result
                        );
                      }}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </td>
                  <td>
                    <select
                      value={item.result}
                      onChange={(e) => {
                        updatePackage(
                          item._id,
                          item.status,
                          item.runningStatus,
                          e.target.value
                        );
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="win">Win</option>
                      <option value="lose">Lose</option>
                      <option value="tie">Tie</option>
                    </select>
                  </td>
                  <td className="flex gap-2">
                    <span className="py-3 px-2 cursor-pointer">
                      <MdEdit
                        onClick={() => {
                          navigate(
                            `/vsl-package-list/edit-package/${item._id}`
                          );
                        }}
                      />
                    </span>
                    <span className="py-3 px-2 cursor-pointer">
                      <MdDelete
                        onClick={() => {
                          deletePackage(item._id);
                        }}
                      />
                    </span>
                    <span className="py-3 px-2 cursor-pointer">
                      <MdLink
                        onClick={() => {
                          window.navigator.clipboard.writeText(
                            `https://www.jordanspicks.com/vsl-packages/${item._id}`
                          );
                          myToast("Link copied to clipboard", "success");
                        }}
                      />
                    </span>
                  </td>
                </tr>
                {accordian === item._id &&
                  item.bets &&
                  item.bets.map((bet, index) => (
                    <tr className="bg-dark" key={bet}>
                      {/* <td colSpan={5}>{bet.title}</td>
                      <td colSpan={4}>
                        <select
                          value={bet.result}
                          onChange={(e) => {
                            updatePackageBet(item._id, bet._id, e.target.value);
                          }}
                        >
                          <option value="pending">Pending</option>
                          <option value="win">Win</option>
                          <option value="lose">Lose</option>
                          <option value="tie">Tie</option>
                        </select>
                      </td> */}
                      <td colSpan={13} className="text-center">
                        Bet {index + 1} - {bet}
                      </td>
                    </tr>
                  ))}
              </>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </Table>
        <Pagination page={page} setPage={setPage} lastPage={lastPage} />
      </div>
      <Modal ref={dialogRef} content={modalBody} />
    </>
  );
};

export default VSLPackages;
