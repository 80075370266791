import { useState, useEffect, useRef } from "react";
import api from "../../../components/utils/api";
import Table from "../../../components/common/Table";
import Pagination from "../../../components/common/Pagination";
import Modal from "../../../components/common/Modal";

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const getPackages = async () => {
    try {
      const { data } = await api.get(
        `/admin/deletedSpecialPackages?page=${page}`
      );
      console.log(data);
      setPackages(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPackages();
  }, [page]);

  const dialogRef = useRef();
  const [modalBody, setModalBody] = useState("");

  const [accordian, setAccordian] = useState(0);
  return (
    <>
      <div>
        <Table
          tHead={[
            "S.No.",
            "Name",
            "Price (Monthly/Yearly)",
            "Subsequent Discount",
            "Content",
            "Links",
          ]}
          wrapperClass="my-8"
        >
          {packages.length > 0 ? (
            packages.map((item, index) => (
              <>
                <tr key={item._id}>
                  <td>{(page - 1) * 10 + index + 1}</td>
                  <td>{item.name}</td>
                  <td>
                    ${item.monthlyPrice} / ${item.yearlyPrice}
                  </td>
                  <td>{item.discount}%</td>
                  <td
                    className="cursor-pointer underline text-yellow"
                    onClick={() => {
                      setModalBody(item.description);
                      dialogRef.current.showModal();
                    }}
                  >
                    show content
                  </td>
                  <td>
                    <span
                      className="cursor-pointer underline text-yellow"
                      onClick={() => {
                        if (accordian === item._id) setAccordian(0);
                        else setAccordian(item._id);
                      }}
                    >
                      {accordian === item._id ? "hide links" : "show links"}
                    </span>
                  </td>
                </tr>
                {accordian === item._id &&
                  item.links &&
                  item.links.map((link, index) => (
                    <tr className="bg-dark" key={link}>
                      <td colSpan={9} className="text-center">
                        Link {index + 1} - {link}
                      </td>
                    </tr>
                  ))}
              </>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </Table>
        <Pagination page={page} setPage={setPage} lastPage={lastPage} />
      </div>
      <Modal ref={dialogRef} content={modalBody} />
    </>
  );
};

export default Packages;
