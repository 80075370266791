import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../components/utils/api";
import myToast from "../../../components/utils/myToast";
import dateFormatter from "../../../components/utils/dateFormatter";
import Button from "../../../components/common/Button";
import Table from "../../../components/common/Table";
import Pagination from "../../../components/common/Pagination";
import Modal from "../../../components/common/Modal";
import { MdEdit, MdDelete } from "react-icons/md";

const Packages = () => {
  const navigate = useNavigate();

  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const getPackages = async () => {
    try {
      const { data } = await api.get(`/admin/allPackages?page=${page}`);
      console.log(data);
      setPackages(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPackages();
  }, [page]);

  const updatePackage = async (id, status, runningStatus, result) => {
    try {
      const { data } = await api.patch(`/admin/updatePackageStatus/${id}`, {
        status,
        // runningStatus,
        result,
      });
      console.log(data);
      getPackages();
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  // const updatePackageBet = async (packageId, betId, result) => {
  //   try {
  //     const { data } = await api.patch(
  //       `/admin/updatePackageBetStatus/${packageId}`,
  //       {
  //         betId,
  //         status: result,
  //       }
  //     );
  //     console.log(data);
  //     getPackages();
  //   } catch (err) {
  //     console.log(err);
  //     myToast(err?.response?.data?.error || "Something went wrong", "failure");
  //   }
  // };
  const deletePackage = async (id) => {
    try {
      const { data } = await api.delete("/admin/deletePackage/" + id);
      console.log(data);
      getPackages();
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  const dialogRef = useRef();
  const [modalBody, setModalBody] = useState("");

  const [accordian, setAccordian] = useState(0);
  return (
    <>
      <div>
        <Table
          tHead={[
            "S.No.",
            "Name",
            "Price",
            "End Date",
            "Sports & Category",
            "Visitors",
            "Content",
            "Bets",
            "Status",
            "Result",
            // "Running Status",
            "Action",
          ]}
          wrapperClass="my-4"
        >
          {packages.length > 0 ? (
            packages.map((item, index) => (
              <>
                <tr key={item._id}>
                  <td>{(page - 1) * 10 + index + 1}</td>
                  <td>{item.name}</td>
                  <td>${item.price}</td>
                  <td>{dateFormatter(item.endDate)}</td>
                  <td>{item.sports} / {item.category}</td>
                  <td>{item.pageCount}</td>
                  <td
                    className="cursor-pointer underline text-yellow"
                    onClick={() => {
                      setModalBody(item.description);
                      dialogRef.current.showModal();
                    }}
                  >
                    show content
                  </td>
                  <td>
                    <span
                      className="cursor-pointer underline text-yellow"
                      onClick={() => {
                        if (accordian === item._id) setAccordian(0);
                        else setAccordian(item._id);
                      }}
                    >
                      {accordian === item._id ? "hide bets" : "show bets"}
                    </span>
                  </td>
                  <td>
                    <select
                      value={item.status}
                      onChange={(e) => {
                        updatePackage(
                          item._id,
                          e.target.value,
                          item.runningStatus,
                          item.result
                        );
                      }}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </td>
                  {/* <td>
                    <select
                      value={item.runningStatus ? "Running" : "Stopped"}
                      onChange={(e) => {
                        updatePackage(
                          item._id,
                          item.status,
                          e.target.value === "Running" ? true : false
                        );
                      }}
                    >
                      <option value="Running">Running</option>
                      <option value="Stopped">Stopped</option>
                    </select>
                  </td> */}
                  <td>
                    <select
                      value={item.result}
                      onChange={(e) => {
                        updatePackage(
                          item._id,
                          item.status,
                          item.runningStatus,
                          e.target.value
                        );
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="win">Win</option>
                      <option value="lose">Lose</option>
                      <option value="tie">Tie</option>
                    </select>
                  </td>
                  <td>
                    <div className="flex gap-2">
                      <span className="py-3 px-2 cursor-pointer">
                        <MdEdit
                          onClick={() => {
                            navigate(`/package-list/edit-package/${item._id}`);
                          }}
                        />
                      </span>
                      <span className="py-3 px-2 cursor-pointer">
                        <MdDelete
                          onClick={() => {
                            deletePackage(item._id);
                          }}
                        />
                      </span>
                    </div>
                  </td>
                </tr>
                {accordian === item._id &&
                  item.bets &&
                  item.bets.map((bet, index) => (
                    <tr className="bg-dark" key={bet}>
                      {/* <td colSpan={5} className="pl-48">
                        {bet.title}
                      </td>
                      <td colSpan={4}>
                        <select
                          value={bet.result}
                          onChange={(e) => {
                            updatePackageBet(item._id, bet._id, e.target.value);
                          }}
                        >
                          <option value="pending">Pending</option>
                          <option value="win">Win</option>
                          <option value="lose">Lose</option>
                          <option value="tie">Tie</option>
                        </select>
                      </td> */}
                      <td colSpan={11} className="text-center">
                        Bet {index + 1} - {bet}
                      </td>
                    </tr>
                  ))}
              </>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </Table>
        <Pagination page={page} setPage={setPage} lastPage={lastPage} />
      </div>
      <Modal ref={dialogRef} content={modalBody} />
    </>
  );
};

export default Packages;
