import { useState, useEffect } from "react";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import { BiSolidPackage } from "react-icons/bi";
import { ImUsers } from "react-icons/im";
import { MdSupportAgent, MdPayments } from "react-icons/md";
import "./dashboard.css";

const Dashboard = () => {
  const [overview, setOverview] = useState({});
  const getOverview = async () => {
    try {
      const { data } = await api.get("/admin/overview");
      console.log(data);
      setOverview(data.dta);
    } catch (err) {
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  useEffect(() => {
    getOverview();
  }, []);

  return (
    <div>
      <h3>Dashboard</h3>
      <div className="flex flex-wrap gap-x-8 gap-y-12 my-12">
        <div className="dashboard-item">
          <label>
            <ImUsers />
          </label>
          <h2 className="text-center">{overview?.totaluser}</h2>
          <p className="text-center font-medium">Total Users</p>
        </div>
        <div className="dashboard-item">
          <label>
            <ImUsers />
          </label>
          <h2 className="text-center">{overview?.users}</h2>
          <p className="text-center font-medium">Verified Users</p>
        </div>
        <div className="dashboard-item">
          <label>
            <MdPayments />
          </label>
          <h2 className="text-center">{overview?.orders}</h2>
          <p className="text-center font-medium">Orders</p>
        </div>
        <div className="dashboard-item">
          <label>
            <BiSolidPackage />
          </label>
          <h2 className="text-center">{overview?.packages}</h2>
          <p className="text-center font-medium">Packages</p>
        </div>
        <div className="dashboard-item">
          <label>
            <BiSolidPackage />
          </label>
          <h2 className="text-center">{overview?.vslPackages}</h2>
          <p className="text-center font-medium">VSL Packages</p>
        </div>
        <div className="dashboard-item">
          <label>
            <MdSupportAgent />
          </label>
          <h2 className="text-center">{overview?.contacts}</h2>
          <p className="text-center font-medium">Contacts</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
