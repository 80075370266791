import { useState } from "react";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";
import TextEditor from "../../components/common/TextEditor";
import { MdClose } from "react-icons/md";
import dateTimeSetter from "../../components/utils/dateTimeSetter";

const AddVSLPackage = () => {
  const [name, setName] = useState("");
  const [actPrice, setActPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10).replace("T", " ")
  );
  const [startTime, setStartTime] = useState("00:00");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().slice(0, 10).replace("T", " ")
  );
  const [endTime, setEndTime] = useState("00:00");
  const [saleTitle, setSaleTitle] = useState("");
  const [description, setDescription] = useState({
    editorHtml: "",
  });
  const [gamePreview, setGamePreview] = useState("");
  const [bets, setBets] = useState([
    {
      title: "",
      result: "pending",
      show: true,
    },
  ]);
  const [videoURL, setVideoURL] = useState(
    "https://www.youtube.com/embed/v7Iy5ikDy4A"
  );

  const addVSLPackage = async () => {
    let myBets = bets.filter((bet) => bet.show).map((bet) => bet.title);
    try {
      const { data } = await api.post("/admin/createVslPackage", {
        name,
        actPrice: +actPrice,
        discountedPrice: +discountedPrice,
        startDate: dateTimeSetter(startDate, startTime),
        endDate: dateTimeSetter(endDate, endTime),
        saleTitle,
        description: description.editorHtml,
        gamePreview,
        bets: myBets,
        videoURL,
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(
        err?.response?.data?.error || "Something went wrong",
        "failure"
      );
    }
  };

  return (
    <div className="add-package">
      <h3>Add VSL Package</h3>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          addVSLPackage();
        }}
      >
        <div className="flex gap-x-10 flex-wrap">
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Package Name</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Sale Title</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={saleTitle}
                onChange={(e) => {
                  setSaleTitle(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Actual Price</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={actPrice}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setActPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Discounted Price</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={discountedPrice}
                onChange={(e) => {
                  if (!isNaN(e.target.value))
                    setDiscountedPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Start Date/Time</div>
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
                required
              />
              <input
                type="time"
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">End Date/Time</div>
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
                required
              />
              <input
                type="time"
                value={endTime}
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Game Preview</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={gamePreview}
                onChange={(e) => {
                  setGamePreview(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">
                Embedded Video URL{" "}
                <span
                  className="text-xs text-yellow cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/watch?v=v7Iy5ikDy4A",
                      "_blank"
                    );
                  }}
                >
                  (View default)
                </span>
              </div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={videoURL}
                onChange={(e) => {
                  setVideoURL(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Bets</div>
              {bets.map((item, index) => (
                <div key={index} className={!item.show ? "hidden" : ""}>
                  <div className="flex justify-between items-center">
                    <div className="text-grey">Bet {index + 1}</div>
                    <span
                      className="cursor-pointer p-2"
                      onClick={() => {
                        let temp = [...bets];
                        temp[index].show = false;
                        setBets(temp);
                      }}
                    >
                      <MdClose />
                    </span>
                  </div>
                  <input
                    className="min-w-[20.5rem]"
                    type="text"
                    value={item.title}
                    onChange={(e) => {
                      let temp = [...bets];
                      temp[index].title = e.target.value;
                      setBets(temp);
                    }}
                    placeholder="Enter Bet Title"
                  />
                </div>
              ))}
              <div className="my-4">
                <Button
                  type="button"
                  theme="dark"
                  className="w-full"
                  onClick={() => {
                    setBets([
                      ...bets,
                      {
                        title: "",
                        result: "pending",
                        show: true,
                      },
                    ]);
                  }}
                >
                  Add Another Bet
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="text-grey font-medium">Content</div>
          <TextEditor
            documentContent={description}
            setDocumentContent={setDescription}
          />
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Add Package
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddVSLPackage;
