import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../components/utils/api";
import dateTimeSetter from "../../components/utils/dateTimeSetter";
import dateFormatter from "../../components/utils/dateFormatter";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";
import TextEditor from "../../components/common/TextEditor";
import { MdClose } from "react-icons/md";

const EditPackage = () => {
  const { id } = useParams();

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [content, setContent] = useState({
    editorHtml: "",
  });
  const [preview, setPreview] = useState("");
  const [bets, setBets] = useState([
    {
      title: "",
      // result: "pending",
      show: true,
      // action: "update", // for backend
    },
  ]);
  const [videoURL, setVideoURL] = useState("");
  const [sports, setSports] = useState("");
  const [category, setCategory] = useState("");

  const updatePackage = async () => {
    // const newBets = bets.filter((item) => item.show);
    const newBets = bets.filter((item) => item.show).map((x) => x.title);
    console.log("actual date", date);
    console.log("actual date2", new Date(date));
    console.log(dateTimeSetter(date, time));
    console.log(new Date(dateTimeSetter(date, time)));
    //print time in ET
    console.log(
      new Date(dateTimeSetter(date, time)).toLocaleString("en-US", {
        timeZone: "America/New_York",
      })
    );
    //print time in asia/kolkata
    console.log(
      new Date(dateTimeSetter(date, time)).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    );
    try {
      const { data } = await api.put(`/admin/updatePackage/${id}`, {
        name,
        price: +price,
        endDate: dateTimeSetter(date, time),
        description: content.editorHtml,
        gamePreview: preview,
        // bets: bets,
        bets: newBets,
        videoURL,
        category,
        sports,
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  const getPackage = async () => {
    try {
      const { data } = await api.get(`/admin/getPackage/${id}`);
      console.log(data);
      setName(data.dta.name);
      setPrice(data.dta.price);
      setContent({
        editorHtml: data.dta.description,
      });
      setPreview(data.dta.gamePreview);
      const bets = data.dta.bets.map((item) => ({
        // ...item,
        title: item,
        show: true,
        // action: "update",
      }));
      setBets(bets);
      let dateWithTime = dateFormatter(data.dta.endDate, true).split(" ");
      setDate(dateWithTime[0]);
      setTime(dateWithTime[1]);
      setVideoURL(data.dta.videoURL);
      setCategory(data.dta.category);
      setSports(data.dta.sports);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPackage();
  }, []);

  return (
    <div className="add-package">
      <h3>Update Package</h3>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          updatePackage();
        }}
      >
        <div className="flex gap-10 flex-wrap">
          <div>
            <div className="my-4">
              <div className="text-grey">Package Name</div>
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Price</div>
              <input
                type="text"
                value={price}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Date</div>
              <input
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                // min={new Date().toISOString().split("T")[0]}
                required
              />
              <input
                type="time"
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Sports</div>
              <select
                className="min-w-[20.5rem]"
                value={sports}
                onChange={(e) => {
                  setSports(e.target.value);
                }}
                required
              >
                <option value="">Select a sport</option>
                <option value="NBA">NBA</option>
                <option value="NFL">NFL</option>
                <option value="NCAAB">NCAAB</option>
                <option value="NHL">NHL</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Package Category</div>
              <select
                className="min-w-[20.5rem]"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                required
              >
                <option value="">Select a category</option>
                <option value="Boss Play">Boss Play</option>
                <option value="Mini Boss Play">Mini Boss Play</option>
                <option value="Hardwood Heatwave">Hardwood Heatwave</option>
                <option value="Frozen Fire">Frozen Fire</option>
              </select>
            </div>
            <div className="my-4">
              <div className="text-grey">Game Preview</div>
              <input
                type="text"
                value={preview}
                onChange={(e) => {
                  setPreview(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Embedded Video URL</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={videoURL}
                onChange={(e) => {
                  setVideoURL(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Bets</div>
              {bets.length > 0 ? (
                bets.map((item, index) => (
                  <div key={index} className={!item.show ? "hidden" : ""}>
                    <div className="flex justify-between items-center">
                      <div className="text-grey">Bet {index + 1}</div>
                      <span
                        className="cursor-pointer p-2"
                        onClick={() => {
                          let temp = [...bets];
                          temp[index].show = false;
                          // temp[index].action = "delete";
                          setBets(temp);
                        }}
                      >
                        <MdClose />
                      </span>
                    </div>
                    <input
                      type="text"
                      value={item.title}
                      onChange={(e) => {
                        let temp = [...bets];
                        temp[index].title = e.target.value;
                        setBets(temp);
                      }}
                    />
                  </div>
                ))
              ) : (
                <div className="text-grey">No Bets</div>
              )}
              <div className="my-4">
                <Button
                  type="button"
                  theme="dark"
                  className="w-full"
                  onClick={() => {
                    setBets([
                      ...bets,
                      {
                        title: "",
                        // result: "pending",
                        show: true,
                        // action: "add",
                      },
                    ]);
                  }}
                >
                  Add Another Bet
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="text-grey">Content</div>
          <TextEditor
            documentContent={content}
            setDocumentContent={setContent}
          />
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Update Package
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditPackage;
