import { useState, useEffect, useRef } from "react";
import api from "../../../components/utils/api";
import dateFormatter from "../../../components/utils/dateFormatter";
import Table from "../../../components/common/Table";
import Pagination from "../../../components/common/Pagination";
import Modal from "../../../components/common/Modal";

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const getPackages = async () => {
    try {
      const { data } = await api.get(`/admin/deletedPackages?page=${page}`);
      console.log(data);
      setPackages(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPackages();
  }, [page]);

  const dialogRef = useRef();
  const [modalBody, setModalBody] = useState("");

  const [accordian, setAccordian] = useState(0);
  return (
    <>
      <div>
        <Table
          tHead={[
            "S.No.",
            "Name",
            "Price",
            "End Date",
            "Sports & Category",
            "Content",
            "Bets",
            "Status",
            "Result",
          ]}
          wrapperClass="my-8"
        >
          {packages.length > 0 ? (
            packages.map((item, index) => (
              <>
                <tr key={item._id}>
                  <td>{(page - 1) * 10 + index + 1}</td>
                  <td>{item.name}</td>
                  <td>${item.price}</td>
                  <td>{dateFormatter(item.endDate)}</td>
                  <td>{item.sports} / {item.category}</td>
                  <td
                    className="cursor-pointer underline text-yellow"
                    onClick={() => {
                      setModalBody(item.description);
                      dialogRef.current.showModal();
                    }}
                  >
                    show content
                  </td>
                  <td>
                    <span
                      className="cursor-pointer underline text-yellow"
                      onClick={() => {
                        if (accordian === item._id) setAccordian(0);
                        else setAccordian(item._id);
                      }}
                    >
                      {accordian === item._id ? "hide bets" : "show bets"}
                    </span>
                  </td>
                  <td>{item.status}</td>
                  {/* <td>
                   {item.runningStatus ? "Running" : "Stopped"}
                     */}
                  <td>{item.result}</td>
                </tr>
                {accordian === item._id &&
                  item.bets &&
                  item.bets.map((bet, index) => (
                    <tr className="bg-dark" key={bet}>
                      {/* <td colSpan={5} className="pl-48">
                        {bet.title}
                      </td>
                      <td colSpan={4}>
                        {bet.result}
                      </td> */}
                      <td colSpan={11} className="text-center">
                        Bet {index + 1} - {bet}
                      </td>
                    </tr>
                  ))}
              </>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </Table>
        <Pagination page={page} setPage={setPage} lastPage={lastPage} />
      </div>
      <Modal ref={dialogRef} content={modalBody} />
    </>
  );
};

export default Packages;
