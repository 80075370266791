import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PassContext from "../../utils/PassContext";
import { RiLogoutBoxFill, RiSendPlaneFill  } from "react-icons/ri";
import Breadcrumbs from "../../common/Breadcrumbs";

const Header = () => {
  const navigate = useNavigate();
  const { setLoggedUser } = useContext(PassContext);

  const tabs = [
    {
      name: "Send Email",
      icon: <RiSendPlaneFill  />,
      func: () => {
        navigate("/user-list/send-email");
      },
    },
    {
      name: "Logout",
      icon: <RiLogoutBoxFill />,
      func: () => {
        localStorage.removeItem("jordanToken");
        localStorage.removeItem("jordanTokenRefresh");
        setLoggedUser("");
        navigate("/auth");
      },
    },
  ];
  return (
    <header>
      <Breadcrumbs />
      <div className="flex gap-6">
        {tabs.map((tab, index) => (
          <div key={index} className="header-tabs" onClick={tab.func}>
            {tab.icon}
            {tab.name}
          </div>
        ))}
      </div>
    </header>
  );
};

export default Header;
