import { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import Button from "../../components/common/Button";

const Packages = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    {
      name: "Live Packages",
      key: "live",
    },
    {
      name: "Past Packages",
      key: "past",
    },
    {
      name: "Deleted Packages",
      key: "deleted",
    },
  ];

  useEffect(() => {
    if (location.pathname)
      location.pathname === "/package-list" && navigate("./live");
  }, [location.pathname]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3>Packages</h3>
        <Button
          theme="yellow"
          onClick={() => {
            navigate("/package-list/add-package");
          }}
        >
          Add Package
        </Button>
      </div>
      <div className="flex gap-4 items-center mt-2 mb-8">
        {tabs.map((tab) => (
          <Button
            key={tab.key}
            theme={location.pathname.includes(tab.key) ? "yellow" : "dark-noborder"}
            size="sm"
            onClick={() => navigate(`./${tab.key}`)}
          >
            {tab.name}
          </Button>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default Packages;
