import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  MdDashboard,
  MdSupportAgent,
  MdPayments,
  MdLocalGroceryStore,
} from "react-icons/md";
import { BiSolidPackage } from "react-icons/bi";
import { ImUsers } from "react-icons/im";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const data = [
    {
      icon: <MdDashboard />,
      name: "Dashboard",
      link: "/",
    },
    {
      icon: <BiSolidPackage />,
      name: "Packages",
      link: "/package-list",
    },
    {
      icon: <BiSolidPackage />,
      name: "Special Packages",
      link: "/special-package-list",
    },
    {
      icon: <BiSolidPackage />,
      name: "VSL Packages",
      link: "/vsl-package-list",
    },
    {
      icon: <MdPayments />,
      name: "All Orders",
      link: "/all-orders",
    },
    {
      icon: <MdLocalGroceryStore />,
      name: "Store",
      link: "/store",
    },
    {
      icon: <ImUsers />,
      name: "Users",
      link: "/user-list",
    },
    {
      icon: <MdSupportAgent />,
      name: "Contacted Users",
      link: "/contacted-users",
    },
  ];

  const [active, setActive] = useState(0);
  useEffect(() => {
    data.forEach((item, index) => {
      if (location.pathname.includes(item.link)) setActive(index);
    });
  }, [location.pathname]);

  return (
    <div className="sidebar">
      <div className="px-6 py-5">
        <Link to="/" className="flex gap-2 items-center">
          <img src="/assets/logo.png" alt="logo" className="h-12 w-12" />
          <span className="font-semibold">Admin Panel</span>
        </Link>
      </div>
    <hr className="mx-5 border-black" />
      <ul className="ml-6">
        {data.map((item, index) => (
          <li
            key={index}
            className={`sidebar-item ${
              active === index ? "active" : "cursor-pointer"
            }`}
            onClick={() => {
              setActive(index);
              navigate(data[index].link);
            }}
          >
            {item.icon}
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
