import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import Button from "../../components/common/Button";
import TextEditor from "../../components/common/TextEditor";
import { MdClose } from "react-icons/md";

const EditPackage = () => {
  const { id } = useParams();

  const [name, setName] = useState("");
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [yearlyPrice, setYearlyPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [content, setContent] = useState({
    editorHtml: "",
  });
  const [preview, setPreview] = useState("");
  const [links, setLinks] = useState([
    {
      title: "",
      show: true,
    },
  ]);
  const [videoURL, setVideoURL] = useState("");

  const updatePackage = async () => {
    const newLinks = links.filter((item) => item.show).map((x) => x.title);
    try {
      const { data } = await api.put(`/admin/updateSpecialPackage/${id}`, {
        name,
        monthlyPrice: +monthlyPrice,
        yearlyPrice: +yearlyPrice,
        discount,
        description: content.editorHtml,
        gamePreview: preview,
        links: newLinks,
        videoURL,
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };
  const getPackage = async () => {
    try {
      const { data } = await api.get(`/admin/getSpecialPackage/${id}`);
      console.log(data);
      setName(data.dta.name);
      setMonthlyPrice(data.dta.monthlyPrice);
      setYearlyPrice(data.dta.yearlyPrice);
      setDiscount(data.dta.discount);
      setContent({
        editorHtml: data.dta.description,
      });
      setPreview(data.dta.gamePreview);
      const links = data.dta.links.map((item) => ({
        title: item,
        show: true,
      }));
      setLinks(links);
      setVideoURL(data.dta.videoURL);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPackage();
  }, []);

  return (
    <div className="add-package">
      <h3>Update Special Package</h3>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          updatePackage();
        }}
      >
        <div className="flex gap-10 flex-wrap">
          <div>
            <div className="my-4">
              <div className="text-grey">Package Name</div>
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Monthly Price</div>
              <input
                type="text"
                value={monthlyPrice}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setMonthlyPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Yearly Price</div>
              <input
                type="text"
                value={yearlyPrice}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setYearlyPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">
                Subsequent Discount (%) on Standard Packages
              </div>
              <input
                type="text"
                value={discount}
                onChange={(e) => {
                  if (!isNaN(e.target.value) && e.target.value <= 100)
                    setDiscount(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Game Preview</div>
              <input
                type="text"
                value={preview}
                onChange={(e) => {
                  setPreview(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey">Embedded Video URL</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={videoURL}
                onChange={(e) => {
                  setVideoURL(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Links</div>
              {links.length > 0 ? (
                links.map((item, index) => (
                  <div key={index} className={!item.show ? "hidden" : ""}>
                    <div className="flex justify-between items-center">
                      <div className="text-grey">Bet {index + 1}</div>
                      <span
                        className="cursor-pointer p-2"
                        onClick={() => {
                          let temp = [...links];
                          temp[index].show = false;
                          // temp[index].action = "delete";
                          setLinks(temp);
                        }}
                      >
                        <MdClose />
                      </span>
                    </div>
                    <input
                      type="text"
                      value={item.title}
                      onChange={(e) => {
                        let temp = [...links];
                        temp[index].title = e.target.value;
                        setLinks(temp);
                      }}
                    />
                  </div>
                ))
              ) : (
                <div className="text-grey">No Links</div>
              )}
              <div className="my-4">
                <Button
                  type="button"
                  theme="dark"
                  className="w-full"
                  onClick={() => {
                    setLinks([
                      ...links,
                      {
                        title: "",
                        show: true,
                      },
                    ]);
                  }}
                >
                  Add Another Link
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="text-grey">Content</div>
          <TextEditor
            documentContent={content}
            setDocumentContent={setContent}
          />
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Update Special Package
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditPackage;
