import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import dateFormatter from "../../components/utils/dateFormatter";
import Table from "../../components/common/Table";
import Pagination from "../../components/common/Pagination";
import { TbGiftFilled } from "react-icons/tb";

const GiftPackage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const getPackages = async () => {
    try {
      const { data } = await api.get(`/admin/allPackages?page=${page}`);
      console.log(data);
      setPackages(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPackages();
  }, [page]);

  const gift = async (packageId) => {
    try {
      const { data } = await api.post(`/admin/giftPackage`, {
        packageId,
        userId: id,
      });
      console.log(data);
      myToast(data.msg, "success");
      navigate("/user-list");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  return (
    <div>
      <h3>Select an Active Package</h3>
      <div>
        <Table
          tHead={[
            "S.No.",
            "Name",
            "Price",
            "End Date",
            "Sports & Category",
            "Action",
          ]}
          wrapperClass="my-4"
        >
          {packages.length > 0 ? (
            packages.map((item, index) => (
              <tr key={item._id}>
                <td>{(page - 1) * 10 + index + 1}</td>
                <td>{item.name}</td>
                <td>${item.price}</td>
                <td>{dateFormatter(item.endDate)}</td>
                <td>
                  {item.sports} / {item.category}
                </td>
                <td
                  className="cursor-pointer text-yellow flex items-center gap-1 font-medium"
                  onClick={() => {
                    gift(item._id);
                  }}
                >
                  <TbGiftFilled /> GIFT
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </Table>
        <Pagination page={page} setPage={setPage} lastPage={lastPage} />
      </div>
    </div>
  );
};

export default GiftPackage;
