import { useState } from "react";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import dateTimeSetter from "../../components/utils/dateTimeSetter";
import Button from "../../components/common/Button";
import TextEditor from "../../components/common/TextEditor";
import { MdClose } from "react-icons/md";

const AddPackage = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState(
    new Date().toISOString().slice(0, 10).replace("T", " ")
  );
  const [time, setTime] = useState("00:00");
  const [content, setContent] = useState({
    editorHtml: "",
  });
  const [preview, setPreview] = useState("");
  const [bets, setBets] = useState([
    {
      title: "",
      show: true,
    },
  ]);
  const [videoURL, setVideoURL] = useState(
    "https://www.youtube.com/embed/v7Iy5ikDy4A"
  );
  const [sports, setSports] = useState("");
  const [category, setCategory] = useState("");

  const addPackage = async () => {
    let myBets = bets.filter((bet) => bet.show).map((bet) => bet.title);

    try {
      const { data } = await api.post("/admin/createPackage", {
        name,
        price: +price,
        endDate: dateTimeSetter(date, time),
        description: content.editorHtml,
        gamePreview: preview,
        videoURL,
        bets: myBets,
        category,
        sports,
      });
      console.log(data);
      myToast(data.msg, "success");
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  return (
    <div className="add-package">
      <h3>Add Package</h3>
      <form
        className="my-8"
        onSubmit={(e) => {
          e.preventDefault();
          addPackage();
        }}
      >
        <div className="flex gap-x-10 flex-wrap">
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Package Name</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Price</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={price}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setPrice(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">End Date/Time</div>
              <input
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
                required
              />
              <input
                type="time"
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Sports</div>
              <select
                className="min-w-[20.5rem]"
                value={sports}
                onChange={(e) => {
                  setSports(e.target.value);
                }}
                required
              >
                <option value="">Select a sport</option>
                <option value="NBA">NBA</option>
                <option value="NFL">NFL</option>
                <option value="NCAAB">NCAAB</option>
                <option value="NHL">NHL</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Package Category</div>
              <select
                className="min-w-[20.5rem]"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                required
              >
                <option value="">Select a category</option>
                <option value="Boss Play">Boss Play</option>
                <option value="Mini Boss Play">Mini Boss Play</option>
                <option value="Hardwood Heatwave">Hardwood Heatwave</option>
                <option value="Frozen Fire">Frozen Fire</option>
              </select>
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">Game Preview</div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={preview}
                onChange={(e) => {
                  setPreview(e.target.value);
                }}
                required
              />
            </div>
            <div className="my-4">
              <div className="text-grey font-medium">
                Embedded Video URL{" "}
                <span
                  className="text-xs text-yellow cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/watch?v=v7Iy5ikDy4A",
                      "_blank"
                    );
                  }}
                >
                  (View default)
                </span>
              </div>
              <input
                className="min-w-[20.5rem]"
                type="text"
                value={videoURL}
                onChange={(e) => {
                  setVideoURL(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div>
            <div className="my-4">
              <div className="text-grey font-medium">Bets</div>
              {bets.map((item, index) => (
                <div key={index} className={!item.show ? "hidden" : ""}>
                  <div className="flex justify-between items-center">
                    <div className="text-grey">Bet {index + 1}</div>
                    <span
                      className="cursor-pointer p-2"
                      onClick={() => {
                        let temp = [...bets];
                        temp[index].show = false;
                        setBets(temp);
                      }}
                    >
                      <MdClose />
                    </span>
                  </div>
                  <input
                    className="min-w-[20.5rem]"
                    type="text"
                    value={item.title}
                    onChange={(e) => {
                      let temp = [...bets];
                      temp[index].title = e.target.value;
                      setBets(temp);
                    }}
                    placeholder="Enter Bet Title"
                  />
                </div>
              ))}
              <div className="my-4">
                <Button
                  type="button"
                  theme="dark"
                  className="w-full"
                  onClick={() => {
                    setBets([
                      ...bets,
                      {
                        title: "",
                        show: true,
                      },
                    ]);
                  }}
                >
                  Add Another Bet
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="text-grey font-medium">Content</div>
          <TextEditor
            documentContent={content}
            setDocumentContent={setContent}
          />
        </div>
        <div className="mt-4">
          <Button type="submit" theme="yellow">
            Add Package
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddPackage;
