import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../components/utils/api";
import myToast from "../../components/utils/myToast";
import dateFormatter from "../../components/utils/dateFormatter";
import Button from "../../components/common/Button";
import Table from "../../components/common/Table";
import Pagination from "../../components/common/Pagination";
import Modal from "../../components/common/Modal";
import { MdEdit, MdDelete } from "react-icons/md";

const Store = () => {
  const navigate = useNavigate();

  const [store, setStore] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const getStore = async () => {
    try {
      const { data } = await api.get(`/admin/allStore?page=${page}`);
      console.log(data);
      setStore(data.dta);
      setLastPage(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getStore();
  }, [page]);

  const deleteStoreItem = async (id) => {
    try {
      const { data } = await api.delete("/admin/deleteStore/" + id);
      console.log(data);
      getStore();
    } catch (err) {
      console.log(err);
      myToast(err?.response?.data?.error || "Something went wrong", "failure");
    }
  };

  const dialogRef = useRef();
  const [modalBody, setModalBody] = useState("");

  const [accordian, setAccordian] = useState(0);
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <h3>Store Items</h3>
          <Button
            theme="yellow"
            onClick={() => {
              navigate("/store/add-item");
            }}
          >
            Add Store Item
          </Button>
        </div>
        <Table
          tHead={[
            "S.No.",
            "Name",
            "Price",
            "Credits",
            "Action",
          ]}
          wrapperClass="my-8"
        >
          {store.length > 0 ? (
            store.map((item, index) => (
              <tr key={item._id}>
                <td>{(page - 1) * 10 + index + 1}</td>
                <td>{item.name}</td>
                <td>${item.price}</td>
                <td>${item.credits}</td>
                <td>
                  <div className="flex gap-2">
                    <span className="py-3 px-2 cursor-pointer">
                      <MdEdit
                        onClick={() => {
                          navigate(`/store/edit-store/${item._id}`);
                        }}
                      />
                    </span>
                    <span className="py-3 px-2 cursor-pointer">
                      <MdDelete
                        onClick={() => {
                          deleteStoreItem(item._id);
                        }}
                      />
                    </span>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </Table>
        <Pagination page={page} setPage={setPage} lastPage={lastPage} />
      </div>
      <Modal ref={dialogRef} content={modalBody} />
    </>
  );
};

export default Store;
